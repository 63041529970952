import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import * as Sentry from '@sentry/browser';

// import '../public/static/normalize.css';
import { withAuthSync } from '../lib/auth';
import * as gtag from '../lib/gtag';
import * as pixel from '../lib/pixel';

import { ErrorProvider } from '../context/error';

import HeadMeta from '../components/HeadMeta';

// GTag
Router.events.on('routeChangeComplete', url => {
  gtag.pageview(url);
  pixel.pageview();
});

// NProgress
Router.events.on('routeChangeStart', url => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// Sentry
Sentry.init({
  dsn: NEXT_APP_SENTRY_DSN,
});

class MyApp extends App {
  componentDidMount() {
    if (process.browser) {
      pixel.init('266075051443462');
      pixel.pageview();
      // pixel.track('ViewContent');
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <HeadMeta />
        <ErrorProvider>
          <Component {...pageProps} />
          {/* <script
                dangerouslySetInnerHTML={{
                  __html: `
                          (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
                          vgo('setAccount', '799436741');
                          vgo('setTrackByDefault', true);
                          ${currentUser?.email ? `vgo('setEmail', '${currentUser?.email}');` : ''}
                          vgo('process');
                      `
                }}
              /> */}
        </ErrorProvider>
      </>
    );
  }
}

export default withAuthSync(MyApp);
